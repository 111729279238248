
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import RSidebar from "@/components/right-sidebar.vue"; // @ is an alias to /src
import ProjectService from "../../../services/project-service";
// import vendorService from '@/services/vendor-service';
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
import Loader from "@/components/loader.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
    Loader
  },
})
export default class Employee extends Vue {
  loading = true;
  public filter = null;
  private title: string = "";
  public create_obj: any = {
    code: "",
    title: "",
    owner: "",
    amount: "",
    start_date: "",
    end_date: "",
    retention_per: "",
    retention_days: "",
    comments: ""
  };
  public fields = [
    {
      key: "code",
      sortable: false,
    },
    {
      key: "title",
      sortable: false,
    },
    {
      key: "owner",
      sortable: false,
    },
    {
      key: "start_date",
      sortable: false,
    },
    {
      key: "end_date",
      sortable: false,
    },
    {
      key: "retention_per",
      sortable: false,
    },
    {
      key: "retention_days",
      sortable: false,
    },
    {
      key: "amount",
      Label: "Project Cost",
      sortable: false,
    },
  ];
  public columns = [
    {
      label: "code",
      field: "code",
    },
    {
      label: "title",
      field: "title",
    },
    {
      label: "customer",
      field: "owner",
    },
    {
      label: "retention_per",
      field: "retention_per",
    },
    {
      label: "retention_days",
      field: "retention_days",
    },
    {
      label: "amount",
      field: "amount",
    },
    {
      label: "start_date",
      field: "start_date",
    },
    {
      label: "end_date",
      field: "end_date",
    },
    {
      label: "type",
      field: "node_type",
    },
  ];

  public items = [];
  public ddl_items: any = [];
  public import_response = "";
  public import_file: any = null;
  // public options_vendor: any = [];
  public selected_items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end


  retrieve() {
    this.loading = true;
    ProjectService.getParent()
      .then((response) => {
        this.items = response.data;
        this.selected_items = response.data;
        this.ddl_items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
    // vendorService.get_list()
    //   .then((response) => {
    //     this.options_vendor = response.data;
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  refreshList() {
    this.retrieve();
  }

  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
    this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
    if (this.import_file != null) {
      ProjectService.importBulk(this.import_file)
        .then((response) => {
          this.retrieve();

          this.import_response = "Uploaded.";
          this.loading = false;
          this.showModal('Uploaded Successfully', [], 'success')


        })
        .catch((e) => {
          this.import_response = "Error occured while uploading.";
          this.loading = false;
          console.log(e);
          this.showModal(e.response.data, [], 'error')
        });
    } else {
      this.import_response = "Please upload the file";
      this.loading = false;
    }
  }
  }

  create() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.create_obj, ['code', 'title', 'owner', 'amount', 'start_date', 'end_date', 'retention_per', 'retention_days', 'comments'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.loading = true;
      // let temp = this.create_obj["vendor"];
      this.create_obj["user"] = 2;

      this.create_obj["vendor"] = null;
      // this.create_obj["vendor"] = temp["id"];
      // this.create_obj["owner"] = temp["title"];
      this.create_obj;
      this.create_obj["node_type"] = "Node";
      ProjectService.create_list(this.create_obj)
        .then((response) => {
          this.resetProject();
          // alert("Successfully Created");
          this.showModal("Created Successfully", [], "success");
          this.retrieve();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }

  searchTitle() {
    this.loading = true;
    ProjectService.getByTitle(this.title)
      .then((response) => {
        this.items = response.data;
        this.selected_items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  onRowClicked(item: any, index: any, event: any) {
    this.$router.push({
      name: "project-create-list",
      params: { id: item.id.toString() },
    });
  }

  mounted() {
    this.retrieve();
  }
  resetProject() {
    this.create_obj = {};
    this.error = "";
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
